<template>
  <div class="products category-med category-last">
    <Breadcrumb :items="breadcrumb" />
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />
    <CategoryTitle :category="category" />
    <v-container class="py-0">
      <v-chip-group show-arrows v-if="parentCategory" class="category-chip">
        <v-chip
          v-for="subCategory in parentCategory.categories"
          :key="subCategory.categoryId"
          link
          exact-active-class="grey darken1"
          :to="
            category.navigationTypeId == 0
              ? { name: 'Category', params: { pathMatch: subCategory.slug } }
              : {
                  name: 'Category',
                  params: {
                    pathMatch: category.slug
                  },
                  query: { categoryId: subCategory.categoryId }
                }
          "
          class="mr-2 mb-2"
        >
          {{ subCategory.name }}
        </v-chip>
      </v-chip-group>

      <ProductListGrid
        :parentCategoryId="category.categoryId"
        :key="category.categoryId"
        hideFilterString="Main Term"
        @productsCount="updateCount"
        :pageSize="$ebsn.meta(category, 'template_model.PAGE_SIZE')"
        :virtualScrollViewport="$vuetify.breakpoint.xsOnly"
        :virtualPageSize="$vuetify.breakpoint.xsOnly ? 4 : 1"
      />
    </v-container>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
    />
    <category-block
      :target="category"
      position="position3"
      class="category-block category-block-3"
    />

    <v-container>
      <ebsn-meta
        :target="category"
        path="category_info.FOOTER_TEXT"
        tag="div"
      />
    </v-container>
  </div>
</template>
<script>
import ProductListGrid from "@/components/product/ProductListGrid.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
// import CategoryBlock from "@/components/categoryBlock/CategoryBlock.vue";

import categoryMixins from "~/mixins/category";
import get from "lodash/get";

export default {
  name: "CategoryLast",
  mixins: [categoryMixins],
  components: {
    ProductListGrid,
    CategoryTitle,
    Breadcrumb
    // ,
    // CategoryBlock
  },
  data() {
    return { count: -1 };
  },
  computed: {
    parentCategory() {
      let pc = this.breadcrumb[this.breadcrumb.length - 2];
      return this.$store.getters["category/findCategoryById"](pc.categoryId);
    }
  },
  methods: {
    updateCount(count) {
      this.count = count;
    }
  },
  metaInfo() {
    const title = get(
      this.category,
      "metaData.category_seo.SEO_TITLE",
      this.category ? this.category.name : ""
    );
    const description = get(
      this.category,
      "metaData.category_seo.SEO_DESCRIPTION",
      "Categoria: " + this.category ? this.category.name : ""
    );
    return {
      title: title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: description
        },
        // Open Graph Metas
        {
          vmid: "og:title",
          property: "og:title",
          content: title
        },
        {
          vmid: "og:type",
          property: "og:type",
          content: "product.group"
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: location.href
        },
        {
          vmid: "og:image",
          property: "og:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          vmid: "og:image:alt",
          property: "og:image:alt",
          content: description
        },
        {
          vmid: "og:site_name",
          property: "og:site_name",
          content: "coopshop.it"
        },
        // Twitter Metas
        {
          name: "twitter:card",
          content: "summary_large_image"
        },
        {
          name: "twitter:title",
          content: title
        },
        {
          name: "twitter:image",
          content:
            "https://" + window.location.host + global.config.socialLogo ||
            "/logo/social-logo.png"
        },
        {
          name: "twitter:url",
          content: location.href
        }
      ]
    };
  }
};
</script>
